import { SubmissionFieldValues } from "@./state";
import { FieldName, SubmitFieldData } from "@forms/schema";
import { BaseAPI } from "@utils/base-api";

export class SurveyAnswers extends BaseAPI {

  public async storeSurveyAnswers(
    surveyKeyValues: SubmissionFieldValues,
    formUsed: string,
    linkingId?: string,
  ) {
    const values = convertMapToRecord(surveyKeyValues);
    try {
      await this.axios.post("answers", {
        values,
        formUsed,
        linkingId,
      });
    } catch (error) {
      console.error("An error occurred when storing answers to the survey", error);
    }
  }

}

export const convertMapToRecord = (
  mapToConvert: Map<FieldName, SubmitFieldData | SubmitFieldData[]>,
): Partial<Record<FieldName, SubmitFieldData | SubmitFieldData[]>> => {
  const newObject: Partial<Record<FieldName, SubmitFieldData | SubmitFieldData[]>> = {
  };
  for (const [ key, value ] of mapToConvert) {
    newObject[key] = value;
  }
  return newObject;
};
